// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-eatgeek-coupon-js": () => import("./../../../src/pages/eatgeek-coupon.js" /* webpackChunkName: "component---src-pages-eatgeek-coupon-js" */),
  "component---src-pages-eatgeek-feedback-js": () => import("./../../../src/pages/eatgeek-feedback.js" /* webpackChunkName: "component---src-pages-eatgeek-feedback-js" */),
  "component---src-pages-eatgeek-loyalty-js": () => import("./../../../src/pages/eatgeek-loyalty.js" /* webpackChunkName: "component---src-pages-eatgeek-loyalty-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-frank-pepe-coupon-js": () => import("./../../../src/pages/frank-pepe-coupon.js" /* webpackChunkName: "component---src-pages-frank-pepe-coupon-js" */),
  "component---src-pages-frank-pepe-feedback-js": () => import("./../../../src/pages/frank-pepe-feedback.js" /* webpackChunkName: "component---src-pages-frank-pepe-feedback-js" */),
  "component---src-pages-frank-pepe-loyalty-js": () => import("./../../../src/pages/frank-pepe-loyalty.js" /* webpackChunkName: "component---src-pages-frank-pepe-loyalty-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loyalty-js": () => import("./../../../src/pages/loyalty.js" /* webpackChunkName: "component---src-pages-loyalty-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-roi-calculator-js": () => import("./../../../src/pages/roi-calculator.js" /* webpackChunkName: "component---src-pages-roi-calculator-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-voice-js": () => import("./../../../src/pages/voice.js" /* webpackChunkName: "component---src-pages-voice-js" */),
  "component---src-templates-lead-gen-page-index-js": () => import("./../../../src/templates/LeadGenPage/index.js" /* webpackChunkName: "component---src-templates-lead-gen-page-index-js" */),
  "component---src-templates-press-article-index-js": () => import("./../../../src/templates/PressArticle/index.js" /* webpackChunkName: "component---src-templates-press-article-index-js" */),
  "component---src-templates-product-template-index-js": () => import("./../../../src/templates/ProductTemplate/index.js" /* webpackChunkName: "component---src-templates-product-template-index-js" */),
  "component---src-templates-restaurant-privacy-page-index-js": () => import("./../../../src/templates/RestaurantPrivacyPage/index.js" /* webpackChunkName: "component---src-templates-restaurant-privacy-page-index-js" */)
}

